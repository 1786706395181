const routes = Object.freeze({
  signin: '/login',
  affiliateNetworks: '/affiliate-networks',
  main: '/',
  dashboard: '/dashboard/:dashboardType',
  velads: '/velads',
  rainmaker: '/rainmaker',
  stats: '/stats',
  offers: '/offers',
  finance: '/finance',
  campaign: '/campaign',
  faq: '/faq',
  users: '/users',
  pwa: '/pwa',
  deepLinking: '/deep-linking',
  agents: '/agents',
})

export default routes
